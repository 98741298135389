
import { HomeProvider, useHomeProvider } from "./provider";
import Drawer from "./drawer/drawer";
import MainView from "./main/main";

function HomePage() {
    return (
        <HomeProvider>
            <View />
        </HomeProvider>
    );
}

function View() {
    const provider = useHomeProvider()
    return (
        <div>
            <div className="d-flex">
                <div className=" d-none d-lg-block">
                    <Drawer />
                </div>
                <div className="vh-100" style={{ width: '1px' }} />
                <div className="w-100 vh-100 overflow-scroll">
                    <MainView />
                </div>
            </div>
            {
                provider.isDrawerOpen &&
                <div
                    className="d-flex d-block d-lg-none position-absolute"
                    style={{ zIndex: 10, top: 0, left: 0, right: 0 }} >
                    <Drawer />
                    <div className="w-100" style={{ backgroundColor: 'rgba(0, 0, 0, 0.135)' }} onClick={() => provider.setOpenDrawer(false)} />
                </div>
            }
        </div>

    );
}

export default HomePage
