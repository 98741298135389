export class BaseResponse<T> {
    headers?: any

    data?: T

    code?: number

    message?: string

    constructor(headers?: any, data?: T, code?: number, message?: string) {
        this.headers = headers
        this.data = data
        this.code = code
        this.message = message
    }

    public isOk(): boolean {
        return this.code === 0 || (this.code! >= 200 && this.code! < 299)
    }
}