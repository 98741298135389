import { ReactElement, RefObject, createContext, useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ClipboardModel } from "../../../model/clipboard-model";
import { HttpBuilder } from "../../../http/http_builder";
import { ApiPath } from "../../../http/api-path";
import { RoutePath } from "../../../route/route-path";
import { HttpStatusCode } from "axios";


const MainContext = createContext<MainType>(null!)

interface MainType {
    clipboards: Array<ClipboardModel>
    setClipboards: (clipboards: Array<ClipboardModel>) => void
    shareUrl: string | undefined
    setShareUrl: (shareUrl: string | undefined) => void
    isOpen: boolean
    setOpen: (isOpen: boolean) => void
    isRequiredPassword: boolean
    setRequiredPassword: (isRequiredPassword: boolean) => void
    isSearchChannel: boolean
    setSearchChannel: (isSearchChannel: boolean) => void
    isShareChannelOpen: boolean
    setIsShareChannelOpen: (isShareChannelOpen: boolean) => void
    contentRef: RefObject<HTMLTextAreaElement>
    passwordRef: RefObject<HTMLInputElement>
    idRef: RefObject<HTMLInputElement>
    optionRef: RefObject<HTMLSelectElement>
    fetchData: () => Promise<void>
    onSubmit: () => any
    makeNewClipboard: () => any
    setShareChannelUrl: () => void
    setShareClipboardUrl: (e: ClipboardModel) => any
    onSearch: () => void
    onAuthentication: () => any
}

export function useMainProvider() {
    return useContext(MainContext)
}


export function MainProvider({ children }: { children: ReactElement }) {
    const [clipboards, setClipboards] = useState<Array<ClipboardModel>>([])
    const [shareUrl, setShareUrl] = useState<string | undefined>()
    const [isOpen, setOpen] = useState<boolean>(false)
    const [isRequiredPassword, setRequiredPassword] = useState<boolean>(false)
    const [isSearchChannel, setSearchChannel] = useState<boolean>(false)
    const [isShareChannelOpen, setIsShareChannelOpen] = useState<boolean>(false)
    const contentRef = useRef<HTMLTextAreaElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)
    const idRef = useRef<HTMLInputElement>(null)
    const optionRef = useRef<HTMLSelectElement>(null)

    const [search, setSearch] = useSearchParams()

    const channelId = search.get('channel')
    console.log(channelId);
    const channelKey = `channel-${channelId}`

    async function fetchData(): Promise<void> {
        setRequiredPassword(false)
        setClipboards([])
        if (!channelId) return
        const password = localStorage.getItem(channelKey)
        const result = await HttpBuilder.main()
            .setPath(ApiPath.clipboardByChannel)
            .setBody({
                channelId: +channelId,
                password: password
            })
            .setFromJson((json) => json.map((e: any) => ClipboardModel.fromJson(e)))
            .post<Array<ClipboardModel>>()
        if (!result.isOk()) {
            if (result.code == HttpStatusCode.Forbidden) {
                setRequiredPassword(true)
            }
            return
        }
        setClipboards(result.data!)
    }


    async function onSubmit() {
        const content = contentRef.current?.value.trim()
        if (!content) return
        await makeNewClipboard()
        fetchData()
        setOpen(false)
    }

    async function makeNewClipboard() {
        await HttpBuilder.main()
            .setPath(ApiPath.clipboard)
            .setBody({
                channelId: +(channelId ?? 0),
                content: contentRef.current?.value?.trim(),
                type: 'text'
            })
            .post()
    }

    function setShareChannelUrl(): void {
        const url = window.location.origin + RoutePath.initial + `?channel=${channelId}`
        setShareUrl(url)
    }

    function setShareClipboardUrl(e: ClipboardModel) {
        const url = window.location.origin + RoutePath.clipDetail + `?id=${e.id}`
        setShareUrl(url)
    }

    function onSearch(): void {
        const id = idRef.current?.value
        if (!id) return
        const option = optionRef.current?.value
        if (option === 'channel') {
            setSearchChannel(false)
            return setSearch((prev) => new URLSearchParams({ ...prev, 'channel': id }))
        }
        const url = window.location.origin + RoutePath.clipDetail + `?id=${id}`
        window.open(url, '_blank')
    }

    function onAuthentication(): void {
        localStorage.setItem(channelKey, passwordRef.current?.value ?? '')
        fetchData()
    }

    useEffect(() => {
        if (!channelId) return
        fetchData()
    }, [channelId])

    const value = {
        clipboards,
        setClipboards,
        shareUrl,
        setShareUrl,
        isOpen,
        setOpen,
        isRequiredPassword,
        setRequiredPassword,
        isSearchChannel,
        setSearchChannel,
        isShareChannelOpen,
        setIsShareChannelOpen,
        contentRef,
        passwordRef,
        idRef,
        optionRef,
        fetchData,
        onSubmit,
        makeNewClipboard,
        setShareChannelUrl,
        setShareClipboardUrl,
        onSearch,
        onAuthentication,
    }
    return (
        <MainContext.Provider value={value}>
            {children}
        </MainContext.Provider>
    )
}