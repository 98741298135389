
import { ReactElement, createContext, useContext, useState } from "react";

const HomeContext = createContext<HomeType>(null!)

interface HomeType {
	isDrawerOpen: boolean
	setOpenDrawer: (isDrawerOpen: boolean) => void
}

export function useHomeProvider() {
	return useContext(HomeContext)
}


export function HomeProvider({ children }: { children: ReactElement }) {
	const [isDrawerOpen, setOpenDrawer] = useState<boolean>(false)

	const value = {
		isDrawerOpen,
		setOpenDrawer,
	}
	return (
		<HomeContext.Provider value={value}>
			{children}
		</HomeContext.Provider>
	)
}
