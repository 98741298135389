
import ItemClipboard from "../home/main/widgets/item-clipboard";
import { ClipboardDetailProvider, useClipboardDetailProvider } from "./provider";

function ClipboardDetailPage() {
    return (
        <ClipboardDetailProvider>
            <View />
        </ClipboardDetailProvider>
    );
}

function View() {
    const provider = useClipboardDetailProvider()
    return (
        <div className="container p-3">
            {
                provider.clipboard &&
                <ItemClipboard item={provider.clipboard} />
            }
            <div className="d-flex justify-content-center p-3 pt-5">
            <a href="/">Go Home</a>
            </div>
        </div>
    );
}

export default ClipboardDetailPage
