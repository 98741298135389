import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './router/home';
import { RoutePath } from './route/route-path';
import ClipboardDetailPage from './router/clipboard-detail';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={RoutePath.initial} element={<HomePage />} />
        <Route path={RoutePath.clipDetail} element={<ClipboardDetailPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
