
import { ReactElement, createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ClipboardModel } from "../../model/clipboard-model";
import { HttpBuilder } from "../../http/http_builder";
import { ApiPath } from "../../http/api-path";

const ClipboardDetailContext = createContext<ClipboardDetailType>(null!)

interface ClipboardDetailType {
	clipboard: ClipboardModel | undefined
	setClipboard: (clipboard: ClipboardModel | undefined) => void
	fetchData: () => Promise<void>
}

export function useClipboardDetailProvider() {
	return useContext(ClipboardDetailContext)
}


export function ClipboardDetailProvider({ children }: { children: ReactElement }) {
	const [clipboard, setClipboard] = useState<ClipboardModel | undefined>()
	const [search] = useSearchParams()
	const clipboardId = search.get('id')

	async function fetchData(): Promise<void> {
		if (!clipboardId) return
		const result = await HttpBuilder.main()
			.setPath(ApiPath.clipboardDetail)
			.setBody({
				id: +clipboardId
			})
			.setFromJson((json) => ClipboardModel.fromJson(json))
			.post<ClipboardModel>()
		if (!result.isOk()) return
		setClipboard(result.data)
	}
	useEffect(() => {
		if (!clipboardId) return
		fetchData()
	}, [clipboardId])

	const value = {
		clipboard,
		setClipboard,
		fetchData,
	}
	return (
		<ClipboardDetailContext.Provider value={value}>
			{children}
		</ClipboardDetailContext.Provider>
	)
}
