import { ClipboardModel } from "../../../../model/clipboard-model";
import linkifyStr from "linkify-string";
import * as linkify from "linkifyjs";
import { useEffect, useState } from "react";

interface ItemClipboardType {
    item: ClipboardModel
    onShowShare?: () => {}
}

const ItemClipboard = (props: ItemClipboardType) => {
    const [components, setComponents] = useState<{
        type: string;
        value: string;
        isLink: boolean;
        href: string;
        start: number;
        end: number;
    }[]>()

    function onCopy() {
        navigator.clipboard.writeText(props.item.content ?? '')
    }

    function _showShare(): void {
        if (props.onShowShare != null)
            props.onShowShare()
    }

    const content = props.item.content

    useEffect(() => {
        if (content) {
            const result = linkify.find(content)
            setComponents(result)
        }
    }, [])


    return (
        <tr>
            <td>{props.item.id}</td>
            <td>
                <div style={{ width: 100 }}>
                    <img src={components?.find((e) => e.type == 'url')?.href} alt="" width={100} />
                </div>
            </td>
            <td className="w-100" style={{ whiteSpace: 'pre-line' }}>
                <div dangerouslySetInnerHTML={{ __html: linkifyStr(content!!, { target: '_blank' }) }}></div>
            </td>
            <td>
                <div className="d-flex">
                    <button onClick={_showShare}>Share</button>
                    <button onClick={onCopy} className="ms-2">Copy</button>
                </div>
            </td>
        </tr>
    );
}

export default ItemClipboard