import { ReactNode } from "react";

interface DialogType {
    children: ReactNode
    isOpen: boolean
}

const Dialog = (props: DialogType) => {

    if (!props.isOpen) {
        return <div></div>
    }


    return (
        <div
            className="d-flex align-items-center justify-content-center position-absolute w-100 h-100 z-1"
            style={{ right: 0, top: 0, backgroundColor: 'rgba(0, 0, 0, 0.135)' }}>
            <div className="bg-white rounded-3">
                {
                    props.children
                }
            </div>
        </div>
    );
}

export default Dialog