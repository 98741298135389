import { ReactElement, RefObject, createContext, useContext, useEffect, useRef, useState } from "react";
import ChannelModel from "../../../model/channel-model";
import { HttpBuilder } from "../../../http/http_builder";
import { ApiPath } from "../../../http/api-path";
import { useSearchParams } from "react-router-dom";


const DrawerContext = createContext<DrawerType>(null!)

interface DrawerType {
    channels: Array<ChannelModel>
    setChannels: (channels: Array<ChannelModel>) => void
    isOpen: boolean
    setOpen: (isOpen: boolean) => void
    channelNameRef: RefObject<HTMLInputElement>
    passwordRef: RefObject<HTMLInputElement>
    fetchData: () => Promise<void>
    onChannelPressed: (e: ChannelModel) => any
    onSubmit: () => any
    makeNewChannel: () => any
}

export function useDrawerProvider() {
    return useContext(DrawerContext)
}


export function DrawerProvider({ children }: { children: ReactElement }) {
    const [channels, setChannels] = useState<Array<ChannelModel>>([])
    const [isOpen, setOpen] = useState<boolean>(false)
    const channelNameRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)

    const [search, setSearch] = useSearchParams()
    const channelId = search.get('channel')

    async function fetchData(): Promise<void> {
        const result = await HttpBuilder.main()
            .setPath(ApiPath.channel)
            .setFromJson((json) => json.map((e: any) => ChannelModel.fromJson(e)))
            .get<Array<ChannelModel>>()

        if (!result.isOk()) return

        setChannels(result.data!!)
        if (!channelId) {

            var lastChannelId = localStorage.getItem('lastChannelId')
            if (!lastChannelId) {
                if (result.data?.length) {
                    lastChannelId = result.data!!.at(0)!!.id!!.toString()
                }
            }
            if (lastChannelId) {
                onChannelPressed({ id: +lastChannelId })
            }
        } else {

        }
    }

    function onChannelPressed(e: ChannelModel) {
        localStorage.setItem('lastChannelId', e.id?.toString() ?? '')
        setSearch((prev) => new URLSearchParams({ ...prev, 'channel': e.id?.toString() }))
    }

    async function onSubmit() {
        const channelName = channelNameRef.current?.value.trim()
        if (!channelName) return
        await makeNewChannel()
        setOpen(false)
    }

    async function makeNewChannel() {
        const result = await HttpBuilder.main()
            .setPath(ApiPath.channel)
            .setBody({
                name: channelNameRef.current?.value.trim(),
                password: passwordRef.current?.value.trim()
            })
            .post()
        if (!result.isOk()) return
        fetchData()
    }

    useEffect(() => {
        fetchData()
    }, [])

    const value = {
        channels,
        setChannels,
        isOpen,
        setOpen,
        channelNameRef,
        passwordRef,
        fetchData,
        onChannelPressed,
        onSubmit,
        makeNewChannel,
    }
    return (
        <DrawerContext.Provider value={value}>
            {children}
        </DrawerContext.Provider>
    )
}