import { useSearchParams } from "react-router-dom";
import ChannelModel from "../../../../model/channel-model";
import '../index.css'

interface ItemChannelType {
    channel: ChannelModel
    onPressed: () => void
}

const ItemChannel = (props: ItemChannelType) => {
    const [search] = useSearchParams()

    return (
        <div className={`w-100 item-channel ${+(search.get('channel') ?? 0) === props.channel.id ? 'active' : ''}`} onClick={props.onPressed}>
            <div className="p-2">
                {
                    props.channel.name
                }
            </div>
            <hr className="p-0 m-0" />
        </div>
    );
}

export default ItemChannel